import React from 'react';
import './style.css';

const Products = () => {
  return (
    <div className="not-found_container">
      <p>404</p>
      <p>Page Not Found</p>
    </div>
  );
};

export default Products;
